import { cookie } from 'Library/cookie';

export class FeatureFlag {
    public static features: Feature[] = [];

    public static set(feature: Feature): void {
        FeatureFlag.features.push(feature);
    }

    public static get(name: string): Feature | undefined {
        return FeatureFlag.features.find(feature => feature.id === name);
    }

    public static isEnabled(name: string): boolean {
        let feature: Feature | undefined = FeatureFlag.get(name);
        if (feature) {
            if (feature.type === 'cookie') {
                return null !== cookie.getItem(feature.name) && cookie.getItem(feature.name) != '0';
            } else if (feature.type == 'const') {
                return typeof window[feature.name] !== 'undefined' && eval(feature.name) == true;
            }
        }

        return false;
    }
}

export type Feature = FeatureToogleCookie;

export interface FeatureToogleCookie {
    id: string;
    type: string;
    name: any;
    enabled?: boolean;
}
