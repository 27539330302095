require('Project/ts/templates/helpers');

import 'Variables';
import { DependenciesObject, DialogQuechuaSettings } from 'BobjollNotify';
import { EventTracker } from 'Library/eventTacker';
import { q } from 'bobjoll/ts/library/gr/dom.q';
import Countdown, { CountdownSettings } from 'bobjoll/ts/partials/countdown-v1.0';
import notify from 'Partials/notify';
import User from 'Project/ts/pods/user/main';
import { ww } from 'bobjoll/ts/library/dom';
import { KEvent } from 'bobjoll/ts/library/event';
export class Campaign {
    public static displayed = false;
    private static readonly default = { countdown: true };
    private static readonly globalVariables = [
        'ACCOUNTS_BASE_URL',
        'BASE_URL',
        'APP_URL',
        'HOW_ATTRIBUTE_AUTHOR_URL',
        'LANGUAGE',
        'BASIC_PRICE',
        'MONTHLY_PRICE',
        'YEARLY_PRICE',
        'NUM_RESOURCES_PREMIUM',
        'NUM_RESOURCES_SELECTION',
        'NUM_RESOURCES',
        'PREMIUM_LICENSE_URL',
        'STANDARD_LICENSE_URL',
        'URL_CATEGORY',
        'URL_RESOURCE_KEYWORDS',
        'URL_STATIC_IMG',
        'LANGUAGE_SHORT',
        'STATIC_URL',
    ];

    private settings: CampaignSettings;

    constructor(settings: CampaignUserSettings) {
        const date = new Date();

        if (User.isPremium() && date <= settings.dateStart && date >= settings.dateEnd) return;

        if (settings.optimizeExperimentID && !this.checkIfExperimentIsActive(settings)) {
            return;
        }

        this.settings = { ...Campaign.default, ...settings };

        if (document.body.dataset.section == 'page-pricing') {
            this.handlePricingListeners();
        } else {
            this.handleListeners();
        }
    }

    private handleListeners() {
        const banner = document.getElementById(`${this.settings.id}`);

        if (banner) {
            Campaign.displayed = true;

            const bannerClose = q('.notification__close', banner);

            if (bannerClose) {
                bannerClose.addEventListener('click', () => {
                    //@ts-ignore
                    notify.dispatchEvent(new KEventHide(this.settings));

                    notify.hide(this.settings.id);
                });
            }

            const link = q('a', banner);

            EventTracker.evaluateIfNoIterationEventAndSend(
                'ga',
                'event',
                this.settings.events.onload.category,
                this.settings.events.onload.action,
                this.settings.events.onload.label,
                {
                    nonInteraction: true,
                },
            );

            if (link) {
                link.addEventListener('click', () => {
                    EventTracker.evaluateIfNoIterationEventAndSend(
                        'ga',
                        'event',
                        this.settings.events.open.category,
                        this.settings.events.open.action,
                        this.settings.events.open.label,
                    );
                    if (('undefined' !== typeof this.settings.closeOnOpen && this.settings.closeOnOpen) || 'undefined' == typeof this.settings.closeOnOpen) {
                        notify.hide(this.settings.id);
                    }
                });
            }

            const countdown = q('.countdown', banner);

            if (countdown) {
                const settings: CountdownSettings = {
                    container: countdown,
                    dateEnd: this.settings.dateEnd,
                };

                if (this.settings.dateStart && this.settings.countdownTotal) {
                    settings.dateStart = this.settings.dateStart;
                    settings.total = this.settings.countdownTotal;
                }

                new Countdown(settings);
            }
        }

        notify.addEventListener('hide', e => {
            const settings = e.extra;

            if (settings.id !== this.settings.id) return;

            const banner = document.getElementById(`${this.settings.id}`);
            const container = document.getElementById(`${this.settings.container}`);

            if (banner && container) {
                container.style.height = '0';
                EventTracker.evaluateIfNoIterationEventAndSend(
                    'ga',
                    'event',
                    this.settings.events.close.category,
                    this.settings.events.close.action,
                    this.settings.events.close.label,
                );
            }
        });
    }

    private handlePricingListeners() {
        const pricingHeroCampaign = q('.pricing__hero--campaign');
        if (pricingHeroCampaign) {
            const countdown = q('.countdown', pricingHeroCampaign);
            if (countdown) {
                const settings: CountdownSettings = {
                    container: countdown,
                    dateEnd: this.settings.dateEnd,
                };

                if (this.settings.dateStart && this.settings.countdownTotal) {
                    settings.dateStart = this.settings.dateStart;
                    settings.total = this.settings.countdownTotal;
                }

                new Countdown(settings);
            }
        }
    }

    private checkIfExperimentIsActive(settings: CampaignUserSettings): boolean {
        if (settings.optimizeExperimentValues) {
            let values = settings.optimizeExperimentValues.split('|');
            let found = values.find(value => {
                return ww.google_optimize && ww.google_optimize.get(settings.optimizeExperimentID) == value;
            });
            return typeof found != 'undefined';
        } else {
            return ww.google_optimize && typeof ww.google_optimize.get(settings.optimizeExperimentID) != 'undefined';
        }
    }
}

class KEventHide extends KEvent {
    constructor(settings: DialogQuechuaSettings) {
        super();
        this.type = `hide`;
        this.extra = settings;
    }
}
interface CampaignSettings {
    countdown: boolean;
    countdownTotal?: number;
    class?: string;
    closeOnOpen?: boolean;
    dateEnd: Date;
    dateStart: Date;
    id: string;
    max?: number;
    expires?: (date: Date) => Date;
    events: {
        onload: gaEvent;
        close: gaEvent;
        open: gaEvent;
    };
    template: Function;
    callback?: Function;
    preload?: boolean;
    preloadAdditional?: string[];
    preloadDependencies?: DependenciesObject[];
    showFree?: boolean;
    showInterestedInSponsor?: boolean;
    showPremium?: boolean;
    showPremiumFreepik?: boolean;
    showPremiumFlaticon?: boolean;
    showPremiumFreepikAnnual?: boolean;
    showPremiumFreepikMonthly?: boolean;
    showPremiumFlaticonAnnual?: boolean;
    showPremiumFlaticonMonthly?: boolean;
    showRenewalCanceledFlaticon?: boolean;
    showRenewalCanceledFreepik?: boolean;
    showRenewalCanceledPlus?: boolean;
    showMobile?: boolean;
    langs?: string[];
    cacheHash?: number;
    timeExpire?: number;
    position?: 'bottom' | 'top';
    container: HTMLElement;
    optimizeExperimentID?: string;
    optimizeExperimentValues?: string;
}

interface gaEvent {
    category: string;
    action: string;
    label: string;
}

export interface CampaignUserSettings {
    countdown?: boolean;
    countdownTotal?: number;
    closeOnOpen?: boolean;
    dateEnd: Date;
    dateStart: Date;
    id: string;
    max?: number;
    expires?: (date: Date) => Date;
    timeExpire?: number;
    events: {
        onload: gaEvent;
        close: gaEvent;
        open: gaEvent;
    };
    template: Function;
    callback?: Function;
    container: HTMLElement;
    preload?: boolean;
    preloadAdditional?: string[];
    preloadDependencies?: DependenciesObject[];
    showFree?: boolean;
    showInterestedInSponsor?: boolean;
    showPremium?: boolean;
    showPremiumFreepik?: boolean;
    showPremiumFlaticon?: boolean;
    showPremiumFreepikAnnual?: boolean;
    showPremiumFreepikMonthly?: boolean;
    showPremiumFlaticonAnnual?: boolean;
    showPremiumFlaticonMonthly?: boolean;
    showRenewalCanceledFlaticon?: boolean;
    showRenewalCanceledFreepik?: boolean;
    showRenewalCanceledPlus?: boolean;
    optimizeExperimentID?: string;
    optimizeExperimentValues?: string;
}

interface GlobalVariables {
    [name: string]: any;
}
