import { Feature, FeatureFlag } from 'Library/featureFlag';

const featureNewIconDetail: Feature = {
    type: 'const',
    id: 'new_icon_detail',
    name: 'FEATURE_NEW_ICON_DETAIL',
};
FeatureFlag.set(featureNewIconDetail);

const sponsorShutterstock: Feature = {
    type: 'const',
    id: 'sponsor_shutterstock',
    name: 'FEATURE_SPONSOR_SHUTTERSTOCK',
};
FeatureFlag.set(sponsorShutterstock);

const shutterstockCoupon: Feature = {
    type: 'const',
    id: 'shutterstock_coupon',
    name: 'FEATURE_SHUTTERSTOCK_COUPON',
};
FeatureFlag.set(shutterstockCoupon);

const sponsorIstock: Feature = {
    type: 'const',
    id: 'sponsor_istock',
    name: 'FEATURE_SPONSOR_ISTOCK',
};
FeatureFlag.set(sponsorIstock);

const downloadSvgSearch: Feature = {
    type: 'const',
    id: 'download_svg_search',
    name: 'FEATURE_DOWNLOAD_SVG_SEARCH',
};
FeatureFlag.set(downloadSvgSearch);

const notificationsHeader: Feature = {
    type: 'const',
    id: 'notifications_header',
    name: 'FEATURE_NOTIFICATIONS_HEADER',
};
FeatureFlag.set(notificationsHeader);

const filterGroupBy: Feature = {
    type: 'const',
    id: 'filter_group_by',
    name: 'FEATURE_FILTER_GROUP_BY',
};
FeatureFlag.set(filterGroupBy);

const enableGoogleAdsDetail: Feature = {
    type: 'const',
    id: 'enable_google_ads_detail',
    name: 'FEATURE_ENABLE_GOOGLE_ADS_DETAIL',
};
FeatureFlag.set(enableGoogleAdsDetail);

const disableAffiliateByCountry: Feature = {
    type: 'const',
    id: 'disable_affiliate_by_country',
    name: 'FEATURE_DISABLE_AFFILIATE_BY_COUNTRY',
};
FeatureFlag.set(disableAffiliateByCountry);
