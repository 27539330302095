import { ww } from 'bobjoll/ts/library/dom';
import { ResourceColor, ResourceConfig, ResourceData, ResourceType } from 'CommonApp/resource/resource.vm';
import { resourceConfigTypes } from 'CommonApp/resource/resourceConfigService';

export class Resource {
    private actionClasses = '.link-icon-detail, .icon--item__context-menu li';
    public resource: ResourceData | undefined;
    public resourceConfig: ResourceConfig;

    constructor() {
        this.handleResourceListener(this.actionClasses);
    }

    public handleResourceListener = (actionClasses: string = this.actionClasses) => {
        const actionElements = document.querySelectorAll(actionClasses);
        ['click', 'auxclick'].forEach(eventType => {
            actionElements.forEach((element: HTMLElement) => {
                element?.addEventListener(eventType, (e: Event) => {
                    this.handleEvent(e);
                });
            });
        });
    };

    public handleEvent = (e: Event) => {
        this.clearResource();
        const buttonTarget = e.currentTarget as HTMLInputElement;
        const elementWithResourceData = buttonTarget.closest('li.icon--item') as HTMLElement;
        if (elementWithResourceData) {
            this.setResourceFromElement(elementWithResourceData);
            if (this.resource?.type) {
                this.setResourceConfig(this.resource.type);
            }
        } else {
            const detailElement = document.querySelector('#detail') as HTMLElement;
            if (detailElement) {
                this.setResourceFromElement(detailElement);
                if (this.resource?.type) {
                    this.setResourceConfig(this.resource.type);
                }
            }
        }
    };

    public getDataFromElement = (element: HTMLElement): ResourceData => {
        return {
            id: element.dataset.id || '',
            name: element.dataset.name || '',
            nameDetail: element.dataset.name_detail || '',
            class: element.dataset.class || '',
            svg: element.dataset.svg || '',
            styleName: element.dataset.style_name || '',
            familyName: element.dataset.family_name || '',
            description: element.dataset.description || '',
            color: (element.dataset.color || 0) as ResourceColor,
            backgroundColor: element.dataset.background_color || '',
            type: (element.dataset.icon_type || 0) as ResourceType,
            downloads: element.dataset.downloads || '',
            license: element.dataset.license || '',
            groupId: element.dataset.group_id || '',
            published: element.dataset.published || '',
            selection: element.dataset.selection || '',
            prefix: element.dataset.prefix || '',
            keyword: {
                name: element.dataset.keyword_name || '',
                link: element.dataset.keyword_link || '',
            },
            category: {
                id: element.dataset.category_id || '',
                name: element.dataset.category_name || '',
                url: element.dataset.category_url || '',
                link: `<a href="${element.dataset.category_url}" class='text-link category-icon'
                title='${element.dataset.category_name}'>${element.dataset.category_name}</a>`,
            },
            tags: element.dataset.tags || '',
            pack: {
                id: element.dataset.pack_id || '',
                name: element.dataset.pack_name || '',
                url: element.dataset.pack || '',
                totalItems: element.dataset.pack_items || '',
                sprite: element.dataset.pack_sprite || '',
                totalItemsText: element.dataset.pack_items_text || '',
            },
            family: {
                id: element.dataset.family_id || '',
                name: element.dataset.family_name || '',
            },
            team: {
                id: element.dataset.team_id || '',
                name: element.dataset.team_name || '',
            },
            resource: {
                alt: element.dataset.alt || undefined,
                title: element.dataset.title || undefined,
                png: element.dataset.png || '',
                mp4: element.dataset.mp4 || undefined,
                gif: element.dataset.gif || undefined,
            },
            style: {
                id: element.dataset.style || '',
                name: element.dataset.style_name || '',
                slug: element.dataset.style_slug || '',
                color: element.dataset.style_color || '',
                shape: element.dataset.style_shape || '',
                url: element.dataset.style_slug|| '',
                styleClass: element.dataset.style_class || '',
            },
            author: {
                id: element.dataset.author || '',
                name: element.dataset.author_name || '',
                avatar: element.dataset.author_avatar || '',
                url: element.dataset.author_website || '',
                icons: element.dataset.author_icons || '',
                totalIconsText: element.dataset.author_icons_text || '',
            },
            hasRaw: element.dataset.raw && element.dataset.raw === '1' ? true : false,
            craft: element.dataset.craft || '0',
            queryParams: (element.querySelector('.icon--holder a.link-icon-detail') as HTMLElement)?.dataset.src || '',
        };
    };

    public setResourceFromElement = (element: HTMLElement): void => {
        this.resource = this.getDataFromElement(element);
    };

    public setResourceConfig = (resourceType: string): void => {
        this.resourceConfig = resourceConfigTypes[resourceType];
        ww.Resource.resourceConfig = resourceConfigTypes[resourceType];
    };

    public clearResource = () => {
        this.resource = undefined;
    };
}
