import xhr from 'Partials/xhr';
import User from 'Project/ts/pods/user/main';

export const oneTapCallback = (googleUser: any) => {
    return xhr(`${GOOGLE_ONE_TAP_LOGIN_URL}&no-redirect=1&lang=${LANGUAGE_SHORT}`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            credential: googleUser.credential,
        },
    }).then(response => {
        if (response.success === false || !response.data?.redirectUrl) {
            User.alert('error-one-tap', response.message, 'error', 'bottom-left');
        } else {
            window.location.href = response.data.redirectUrl;
        }
    });
};
