import { Autopromo } from 'Project/ts/common-app/autopromo/autopromo';
import { Campaign } from 'Pods/quechua/campaign';
import { Currency } from 'Library/currency';
import { ww } from 'bobjoll/ts/library/dom';
import delay from 'Library/delay';

ww.delay = delay;
window.addEventListener('userSessionLoaded', () => {
    Currency.get().then(() => {
        if (ACTIVE_CAMPAIGN != '') {
            const settings = {
                ...ACTIVE_CAMPAIGN,
                dateEnd: new Date(ACTIVE_CAMPAIGN.dateEnd),
                dateStart: new Date(ACTIVE_CAMPAIGN.dateStart),
            };
            new Campaign(settings);
        } else {
            Autopromo.couponAbandonedCart();
        }
        ww.notify.printQueue();
    });
});
