import { isMobile } from 'Library/helpers/mobile';
import { q, ww } from 'bobjoll/ts/library/dom';

export class CollectionsTour {
    private static storageKey = 'tour_viewed';

    public static handleShow() {
        if (isMobile() || ww.view.section != 'icon-search' || localStorage.getItem(this.storageKey)) {
            return false;
        }
        this.handleListeners();
        let pagesViewed = localStorage.getItem('n_pageviews') || '0';
        if (parseInt(pagesViewed) > 4) {
            this.open();
            localStorage.setItem(this.storageKey, 'true');
        }
        return true;
    }

    private static open() {
        const collectionsElement = q('#collections');
        const collectionsTourElement = q('#collections-tour');
        if (collectionsElement && !collectionsElement.classList.contains('visible')) {
            collectionsElement.classList.add('visible');
        }
        if (document.getElementById('viewport')) {
            document.getElementById('viewport')?.classList.add('collections-visible');
        }
        if (collectionsTourElement) {
            collectionsTourElement.classList.remove('hide');
        }
    }

    private static close() {
        const collectionsTourElement = q('#collections-tour');
        if (collectionsTourElement) {
            collectionsTourElement.classList.add('hide');
        }
    }

    private static handleListeners() {
        q('#collections-tour .button__close')?.addEventListener('click', e => {
            this.close();
        });
    }
}
