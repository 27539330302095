const getBottomPositionFromElement = (element: HTMLElement): number => {
    const elementHeigth = element.getBoundingClientRect().height;
    const elementTopOffset = element.getBoundingClientRect().top;
    return elementHeigth + elementTopOffset;
};

const authorStyleHeaderSticky = (): void => {
    const footer = document.getElementById('footer');
    const authorStyleHeader = document.getElementById('author-style-header');
    if (!footer || !authorStyleHeader) {
        return;
    }

    const observer = new IntersectionObserver(changes => {
        const authorStyleHeaderBottomPosition = getBottomPositionFromElement(authorStyleHeader);
        const toggleStikyPosition = () => {
            const topFooter = footer.getBoundingClientRect().top;
            if (authorStyleHeader.classList.contains('sticky')) {
                if (topFooter <= authorStyleHeaderBottomPosition) {
                    authorStyleHeader.classList.remove('sticky');
                }
            } else if (topFooter > authorStyleHeaderBottomPosition) {
                authorStyleHeader.classList.add('sticky');
            }
        };
        for (const change of changes) {
            if (change.isIntersecting) {
                document.addEventListener('scroll', toggleStikyPosition);
            } else {
                document.addEventListener('scroll', toggleStikyPosition);
            }
        }
    });

    observer.observe(footer);
};

window.addEventListener('load', authorStyleHeaderSticky);
