export function getPathValue(path: string, dictionary: IndexSignature = window) {
    return path.split('.').reduce(function(obj, key) {
        if (obj) {
            return obj[key];
        }
        return false;
    }, dictionary);
}

interface IndexSignature {
    [name: string]: any;
}
