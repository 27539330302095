/// <reference path="./declaration/pickr.min.d.ts" />

import 'Variables';
import 'UI/features';
import 'regenerator-runtime/runtime';
import 'UI/campaign';
import 'UI/features';
import 'Variables';
import 'Project/ts/pages/author-style/authorStyleHeader';

import { ww } from 'bobjoll/ts/library/dom';
import { handleLoginEventsListener } from 'CommonApp/eventsAfterLoginHelper';
import { Resource } from 'CommonApp/resource/resourceService';
import { getTypeUser, getTypeView } from 'CommonFI/datalayer.helpers';
import { cookie } from 'Library/cookie';
import { Currency } from 'Library/currency';
import { DataLayerEvent, EventTracker } from 'Library/eventTacker';
import { FeatureFlag } from 'Library/featureFlag';
import { numberNormalizer } from 'Library/helpers';
import { getPathValue } from 'Library/helpers/path';
import Logs from 'Library/logs';
import notify from 'Partials/notify';
import getXHR from 'Partials/xhr';
/**
 * Bj Components
 */
import { CollectionsTour } from 'Pods/collections/tour';
import { isAdBlockEnabled } from 'Project/ts/common/adBlock';
import { oneTapCallback } from 'Project/ts/common-app/onetap';
import { View } from 'Project/ts/common-app/view';
import User from 'Project/ts/pods/user/main';
import UserSession from 'Project/ts/pods/user/userSession';
import { getUserSession } from 'Project/ts/pods/user/userSessionService';
import Lazyload from 'UI/lazyload';

import { storeDownloadStatsWhenChange } from './pods/user/storeDownloadStats';

ww.setDefaultAvatar = (elem: HTMLImageElement, avatarUrl: string) => {
    if (elem) {
        elem.src = avatarUrl;
    }
};

ww.Handlebars = require('handlebars');
ww.isAdBlockEnabled = isAdBlockEnabled;
ww.Logs = Logs;
ww.Currency = Currency;
ww.Lazyload = Lazyload;
ww.cookie = cookie;
ww.FeatureFlag = FeatureFlag;
ww.notify = notify;
ww.Resource = new Resource();
ww.getPathValue = getPathValue;
ww.view = new View();
ww.oneTapCallback = oneTapCallback;

getUserSession().then(response => {
    if (response.success) {
        if (!localStorage.getItem('session/user') || !('local' in response) || IS_API_ORIGIN) {
            const user = {
                value: JSON.stringify(response.data),
                expiry: response.expiry,
            };
            localStorage.setItem('session/user', JSON.stringify(user));
        }
    }

    UserSession.init();
    ww.UserSession = UserSession;

    if (ww.UserSession.userInfo.logged === true) {
        User.init();

        if (IS_API_ORIGIN) {
            ww.UserSession.handleUserApi();
        }
    }

    View.obtainSectionAndDispatch();
    storeDownloadStatsWhenChange();

    CollectionsTour.handleShow();

    if (ww.gbjs) {
        window.dispatchEvent(new CustomEvent('userSessionLoaded'));
    } else {
        window.addEventListener('globalLoaded', () => {
            window.dispatchEvent(new CustomEvent('userSessionLoaded'));
        });
    }

    handleLoginEventsListener();

    const page404 = document.querySelector('.errorpage.e404') ? '404-not-found' : undefined;

    const userStatus = ww.UserSession.userInfo.logged ? 'logged' : 'not-logged';
    const city: string | null = cookie.getItem('city');
    const userCity: string = city ? decodeURIComponent(city) : 'n/a';

    let ga4Params: DataLayerEvent = {
        event: 'gtm.datalayer',
        content_group: getTypeView(),
        user_type: getTypeUser(),
        user_login_status: userStatus,
        country: ww.USER_COUNTRY_CODE,
        city: userCity,
    };
    if (ww.UserSession.userInfo.logged) {
        ga4Params = { ...ga4Params, user_id: ww.UserSession.userInfo.session.id };
    }
    if (page404) {
        ga4Params['page_code'] = page404;
    }

    EventTracker.send('ga4dl', ga4Params);
});

if (document.getElementById('form_filters')) {
    import('Pods/filters/filters').then(module => {
        module.default.init();
    });
}

Logs.consumeQueue();

const debugPerformance = cookie.getItem('debug_performance');
if (debugPerformance) {
    const typePerformance: PerformanceType = {
        cls: 'layout-shift',
        lcp: 'largest-contentful-paint',
        fid: 'first-input',
    };
    const observeType = typePerformance[debugPerformance];
    try {
        console.info(`--- PERFORMANCE ${observeType} ---`);
        let hiddenTime = document.visibilityState === 'hidden' ? 0 : Infinity;

        document.addEventListener(
            'visibilitychange',
            event => {
                hiddenTime = Math.min(hiddenTime, event.timeStamp);
            },
            { once: true },
        );

        new PerformanceObserver(entryList => {
            entryList.getEntries().forEach(entry => {
                if (entry.startTime < hiddenTime) {
                    console.info(entry);
                }
            });
        }).observe({ type: observeType, buffered: true });
    } catch (e) {
        console.error('Performance API is not supported by this browser');
    }
}

interface PerformanceType {
    [key: string]: string;
}

if (!cookie.getItem('sponsor-chance')) {
    const sponsorPercentage = 1 / 100;
    cookie.setItem('sponsor-chance', (Math.random() < sponsorPercentage ? 1 : 0).toString(), {
        expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 365),
    });
}

const expiredCookiePurchasePromo = new Date().getTime() + 2 * 24 * 60 * 60 * 1000; // 2 days

if (!cookie.getItem('purchase-promo') && cookie.getItem('nDownloadLimit')) {
    cookie.setItem('purchase-promo', '1', {
        expires: new Date(expiredCookiePurchasePromo),
    });
}

if (!cookie.getItem('purchase-promo') && cookie.getItem('visit-plans-pricing')) {
    cookie.setItem('purchase-promo', '1', {
        expires: new Date(expiredCookiePurchasePromo),
    });
}

if (!cookie.getItem('purchase-promo') && 'countdownTimeLeft' in localStorage) {
    localStorage.removeItem('countdownTimeLeft');
}

ww.ONE_PERCENT_CHANCE = numberNormalizer(cookie.getItem('sponsor-chance') || '0');

function getUsersDataChanges() {
    const urlUsersDataChanges = `${APP_URL}ajax/check-user-changes`;

    getXHR(urlUsersDataChanges)
        .then(async response => {
            let checkUserData = response.success;

            if (checkUserData != false) {
                let userDataMsg = response.data.message;
                User.alert(`subscription-upgrade-error`, userDataMsg, 'warning', 'bottom-left');

                const notificationIconElement = document.querySelector(
                    '#subscription-upgrade-error .notification__state',
                ) as HTMLElement;
                notificationIconElement.innerHTML =
                    '<i class="icon icon--sm icon--exclamation icon--mg-sm text-inverted"></i>';
            }
        })
        .catch(error => {
            console.error(error);
        });
}

window.onload = function() {
    getUsersDataChanges();

    setTimeout(() => {
        document.querySelector('#onetrust-banner-sdk.otFlat')?.classList.add('active');
        document.querySelector('#cky-consent')?.classList.add('active');
        window.dispatchEvent(new Event('formTrustpilot'));
    }, 50);

    if (ESSENTIALS_STATUS && ESSENTIALS_STATUS === 'up-s2') {
        if (!localStorage.getItem('modal-unpaid-showed') || localStorage.getItem('modal-unpaid-showed') === 'true') {
            import('./common-app/essentials/essentials').then(module => {
                module.Essentials.handleUnpaidUser();
            });

            const closeModalButton = document.querySelector('#modal-essentials .modal__close');
            closeModalButton &&
                closeModalButton.addEventListener('click', () => {
                    document.getElementById('essentials')?.classList.add('active');
                    localStorage.setItem('modal-unpaid-showed', 'closed');
                });
            localStorage.setItem('modal-unpaid-showed', 'true');
        }
    }

    if (ESSENTIALS_STATUS !== 'up-s2' && localStorage.getItem('modal-unpaid-showed')) {
        localStorage.removeItem('modal-unpaid-showed');
    }

    if (ww.FeatureFlag.isEnabled('notifications_header')) {
        const notificationCenterMenuTrigger = document.getElementById('notification-center-menu-trigger');
        const notificationCenterTitleIconClose = document.getElementById(
            'notification-center-menu-side__title-icon-close',
        );
        const notificationCenterMenu = document.getElementById('notification-center-menu-side');

        const overlayNotifications = document.getElementById('overlay-notifications');
        const viewport = document.getElementById('viewport');

        const collectionsCloseButton: HTMLButtonElement | null = document.querySelector(
            '#collections .hide-collections',
        );
        const openCloseNotificationCenter = () => {
            const widgetCollectionsActive = ww.localStorage.getItem('widget_active');
            if (widgetCollectionsActive === '1' && collectionsCloseButton) {
                collectionsCloseButton.click();
            }

            notificationCenterMenu?.classList.toggle('active');
            overlayNotifications?.classList.toggle('active');
            document.querySelector('body')?.classList.toggle('overflow-hidden');
            if (viewport) {
                viewport.style.filter = 'none';
            }
        };

        notificationCenterMenuTrigger?.addEventListener('click', () => {
            openCloseNotificationCenter();
        });
        notificationCenterTitleIconClose?.addEventListener('click', () => {
            openCloseNotificationCenter();
        });
        overlayNotifications?.addEventListener('click', () => {
            openCloseNotificationCenter();
        });
    }
};
