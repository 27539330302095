import UserSession from './userSession';
import { GrAuthMe, UserDownloadStats, UserGuestInfo } from './userSession.vm';
import { updateUserSessionValue } from './userSessionService';

const updateLocalStorage = async (data: UserDownloadStats) => {
    const { now, max } = data;

    const grUserMe: GrAuthMe | UserGuestInfo = gr.auth.me;

    grUserMe.downloadStats = data;
    UserSession.userInfo.session.downloadStats = data;

    if (!grUserMe.id) {
        return;
    }

    updateUserSessionValue({
        d: now,
        ld: max,
    });
};

export function storeDownloadStatsWhenChange() {
    window.addEventListener('userDownloadStatsUpdated', async (event: CustomEvent<UserDownloadStats>) => {
        updateLocalStorage(event.detail);
    });
}
